import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import {
  Caption,
  Container,
  DynamicImage,
  FlexWrapper,
  H1,
  Svg,
} from '../../../../components';
import { greyDark, white } from '../../../../styles/colors';
import { mobile, tablet } from '../../../../styles/breakpoints';
import { Experiments } from 'components/Experiments';

interface Props {
  onSelect: (gender: string) => void;
}

export const Header: React.FC<Props> = ({ onSelect }) => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <Container>
      <DynamicImage
        src="logo"
        width="auto"
        height={isMobile ? '2rem' : '2.5rem'}
        padding="2rem 0"
        alt=""
      />
      <FlexWrapperStyled alignItems="center" flexDirection="row-reverse">
        <StyledDynamicImage src="header_image" alt="header_image" />
        <InfoWrapper>
          <H1 margin="0 0 1rem 0">Take your personal weight loss to-do list</H1>
          <Caption margin="0 0 2rem 0" color={greyDark}>
            Find out what works for you with this <b>60-sec quiz</b> approved by
            our experts.
          </Caption>
          <Experiments
            id={['test1', 'test2']}
            variants={{
              default: (
                <Caption margin="0 0 1.5rem 0" color={greyDark}>
                  Is your dog a boy or a girl?
                </Caption>
              ),
              test1: (
                <Caption margin="0 0 1.5rem 0" color={greyDark}>
                  Is your dog a boy or a girl?
                </Caption>
              ),
              test2: (
                <Caption margin="0 0 1.5rem 0" color={greyDark}>
                  Is your dog a boy or a girl?
                </Caption>
              ),
            }}
          />

          <FlexWrapper alignItems="center">
            <ButtonWrapper
              alignItems="center"
              margin="0 0.5rem 0 0"
              bg="#3BB367"
              onClick={() => onSelect('male')}
            >
              <Caption margin="0 auto 0 0" color={white}>
                MALE
              </Caption>
              <Svg src="arrow" height="16px" width="16px" />
            </ButtonWrapper>
            <ButtonWrapper
              alignItems="center"
              margin="0 0 0 0.5rem"
              bg="#BF8DB7"
              onClick={() => onSelect('female')}
            >
              <Caption margin="0 auto 0 0" color={white}>
                FEMALE
              </Caption>
              <Svg src="arrow" height="16px" width="16px" />
            </ButtonWrapper>
          </FlexWrapper>
        </InfoWrapper>
      </FlexWrapperStyled>
    </Container>
  );
};

const FlexWrapperStyled = styled(FlexWrapper)`
  margin-top: 6rem;

  @media ${tablet} {
    display: block;
    margin: 0;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 50%;

  @media ${tablet} {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const InfoWrapper = styled.div`
  width: 50%;
  padding-right: 2rem;

  @media ${tablet} {
    width: 100%;
    padding: 0;
  }
`;

const ButtonWrapper = styled(FlexWrapper)`
  width: 50%;
  padding: 1rem 2rem;
  margin: ${props => props.margin};
  background: ${props => props.bg};
  border-radius: 10px;
  cursor: pointer;
`;
